/* Estilos generales */

.MuiTab-root,
.MuiTabs-root {
  min-height: 40px !important;
}

.MuiTabs-root .MuiBadge-badge {
  height: 16px;
  width: 16px;
  min-width: 16px;
  font-size: 0.75rem !important;
}

.MuiTabs-root .MuiSvgIcon-root {
  font-size: 1.3rem;
}

.MuiInputBase-input {
  color: #000 !important;
}

.MuiBadge-colorPrimary {
  background-color: #03a9f4 !important;
}

.MuiButton-textPrimary {
  color: #000000 !important;
}

.MuiButton-textPrimary.Mui-disabled {
  color: rgba(0, 0, 0, 0.26) !important;
}

.MuiTabs-indicator {
  background-color: #065d54 !important;
}

.MuiDropzoneArea-text {
  white-space: pre;
}

.MuiDialogTitle-root > h2 > p {
  font-size: 0.75rem;
  white-space: pre;
  color: #616161;
  margin: 0;
}

.MuiDialog-container {
  background-color: #000000b0;
}

.MuiDialog-paperWidthMd {
  min-width: 960px;
}

body {
  margin: 0 !important;
}

#root {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 0;
  z-index: 1000;
}

#input-group-name-contact-list-bar {
  color: #fff !important;
}

/*Esta sección es para corregir los CSS de la hoja de estilos de la página de UNIR que pisan todas las etiquetas estandar HTML*/

/******************************************************************************/

#proeduca-messenger a {
  color: #42a5f5;
}

#proeduca-messenger p {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

#proeduca-messenger {
  width: 100%;
}

#proeduca-messenger input {
  height: 1.1876em;
  border: none !important;
  font: inherit;
  padding-left: 0;
  color: #000000;
  box-shadow: none !important;
}

#proeduca-messenger textarea {
  min-height: 20px;
  color: #000000;
  box-shadow: none !important;
  border: none !important;
}

#proeduca-messenger p {
  font: inherit;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.01071em;
}

#proeduca-messenger p span {
  font: inherit;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

#proeduca-messenger span {
  font: inherit;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
}

#proeduca-messenger .MuiTypography-root span {
  font: inherit;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

#proeduca-messenger .MuiChip-label {
  font: inherit;
  letter-spacing: 0;
}

#proeduca-messenger .makeStyles-customChip-34 span {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: inherit;
}

#proeduca-messenger ::-webkit-scrollbar {
  width: 10px;
}

#proeduca-messenger ::-webkit-scrollbar-track {
  background: #fff;
}

#proeduca-messenger ::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 10px;
}

/* DARK */

#proeduca-messenger-dark a {
  color: #42a5f5;
}

#proeduca-messenger-dark p {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

#proeduca-messenger-dark {
  width: 100%;
}

#proeduca-messenger-dark input {
  height: 1.1876em;
  border: 0;
  font: inherit;
  padding-left: 0;
  color: #fff;
}

#proeduca-messenger-dark textarea {
  min-height: 20px;
  color: #fff;
}

#proeduca-messenger-dark p {
  font: inherit;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.3;
  letter-spacing: 0.01071em;
  color: #fff;
}

#proeduca-messenger-dark a:hover {
  color: #fff;
}

#proeduca-messenger-dark p span {
  font: inherit;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

#proeduca-messenger-dark span {
  font: inherit;
  font-size: 1rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: #fff;
}

#proeduca-messenger-dark .MuiTypography-root span {
  font: inherit;
  font-size: 0.875rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  line-height: 1.43;
  letter-spacing: 0.01071em;
}

#proeduca-messenger-dark .MuiChip-label {
  font: inherit;
  letter-spacing: 0;
}

#proeduca-messenger-dark .makeStyles-customChip-34 span {
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-size: 12px;
  font-weight: inherit;
}

#proeduca-messenger-dark ::-webkit-scrollbar {
  width: 10px;
}

#proeduca-messenger-dark ::-webkit-scrollbar-track {
  background: #2d2d30;
}

#proeduca-messenger-dark ::-webkit-scrollbar-thumb {
  background: #202020;
  border-radius: 10px;
}
